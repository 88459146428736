require("jquery-validation");
import Inputmask from "inputmask";

// Маска инпутов даты
// let dateMaskOptions = {
//     mask: "D",
//     definitions: {
//         "D": {
//             validator: "[39.19.2099]"
//         }
//     },
// };
// Маска инпутов телефона
let emailMaskOptions = {
    mask: "E{2,191}",
    definitions: {
        "E": {
            validator: "[a-zA-Z-_@.'+]"
        }
    },
    placeholder: ''
};
// Маска инпутов имени
let phoneMaskOptions = {
    mask: "(P{1}V{1})|(E{1}) 999 999 99 99",
    placeholder: '',
    definitions: {
        "P": {
            validator: "[+]"
        },
        "V": {
            validator: "[7]"
        },
        "E": {
            validator: "[8]"
        }
    },
    jitMasking: true
};

let validationOptions = {
    invalidHandler: function (event, validator) {
        let scrollDestination = 0;
        $.each(validator.invalid, function (index, value) {
            let element = $('#' + index);
            if (!element.closest('form').hasClass('no-scroll')) {
                if (scrollDestination === 0 || scrollDestination > element.offset().top) {
                    scrollDestination = element.offset().top;
                }
            }
        });

        if (scrollDestination) {
            if ($.browser) {
                $('body').animate({scrollTop: scrollDestination}, 1100); //1100 - скорость
            } else {
                $('html').animate({scrollTop: scrollDestination}, 1100);
            }
        }
    },
    errorElement: "span",
    errorClass: "not-validated border-red",
    rules: {
        'name': {
            required: true
        },
        'email': {
            required: true,
            email: true
        },
        'phone': {
            required: true,
            checkPhoneMask: true
        },
        'address': {
            required: true,
            courierAvailable: true
        }
    },
    messages: {
        'name': {
            required: "Поле «Имя» обязательно для заполнения"
        },
        'email': {
            required: "Поле «Email» обязательно для заполнения",
            email: "Данные поля «Email» не являются e-mail адресом"
        },
        'phone': {
            required: "Укажите номер из 11 цифр",
            checkPhoneMask: "Укажите номер из 11 цифр"
        },
        'address': {
            required: "Поле «Адрес доставки» обязательно для заполнения",
            courierAvailable: "Курьерская доставка по адресу невозможна"
        }
    }
};
$(document).ready(function () {
    $.validator.addMethod("checkPhoneMask", function (phone, element) {
        return Inputmask.isValid(phone, phoneMaskOptions);
    });
    $.validator.addMethod("courierAvailable", function () {
        return $('#geolocation').val().length > 1;
    });

    // select выбора города доставки
    $(this).on('change', ".order-city-select", function () {
        $.request('onChangeCity', {
            data: {
                city: $(this).val()
            }
        });
    });

    // согл на обработку персональных данных
    $('#confirmDataOrder').on('change', function () {
        if ($(this).prop('checked')) {
            $('#submit-order').prop('disabled', false);
        } else {
            $('#submit-order').prop('disabled', true);
        }
    });
    $('#confirmDataSubscription').on('change', function () {
        if ($(this).prop('checked')) {
            $('#submit-subscription').attr('disabled', false);
        } else {
            $('#submit-subscription').attr('disabled', true);
        }
    });

    $(this).on('change', '.payment-method input', function () {
        $('#payment').html($(this).siblings('label').html());
    });
    $('#payment').html($('.payment-method input:checked').siblings('label').html());

    $(this).on('ajaxComplete', function () {
        Inputmask(phoneMaskOptions).mask('#phone');
        Inputmask(emailMaskOptions).mask('#email');
    });
    Inputmask(phoneMaskOptions).mask('#phone');
    Inputmask(emailMaskOptions).mask('#email');

    $('#submit-order').on('click', function () {
        let form = $('#order');
        form.validate(validationOptions);
        if (form.valid()) {
            $(this).prop('disabled', true);
            $.request('onGetNewOrder', {
                data: form.serializeArray()
            });
        }
    });

    $('#submit-subscription').on('click', function () {
        let form = $('#subscription');
        form.validate(validationOptions);
        if (form.valid()) {
            $.request('onSubscribe', {
                data: form.serializeArray()
            });
            $('#subscribeBlock').hide();
            $('#successMail').show();
        }
    });

    $(this).on('input', '.main-input', function () {
        if ($(this).val()) {
            $(this).parent().addClass('fill');
        } else {
            $(this).parent().removeClass('fill');
        }
    });
    $(this).on('textarea', '.main-input', function () {
        if ($(this).val()) {
            $(this).parent().addClass('fill');
        } else {
            $(this).parent().removeClass('fill');
        }
    });
});
