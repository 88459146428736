window.$ = window.jQuery = require('jquery');
require("jquery-lazyload");
require("select2");
require("owl.carousel");
$(document).ready(function () {
    $("img.lazy").lazyload({
        effect: "fadeIn"
    });

    $('select.select').select2({
        minimumResultsForSearch: -1
    });

    $(this).on('ajaxComplete', function () {
        $('select.select').select2({
            minimumResultsForSearch: -1
        });
    });
    $(".owl-mainCard").owlCarousel({
        lazyLoad:true,
        margin: 32,
        loop: true,
        autoWidth: true,
        items: 1,
        dots: true,
        responsive: {
            0: {
                margin: 1,
            },
            768: {
                margin: 32,
            },
            992: {
                margin: 48
            }
        }
    });
    $(".owl-history").owlCarousel({
        lazyLoad:true,
        margin: 24,
        loop: true,
        autoWidth: true,
        items: 1,
        dots: true,
        responsive: {
            0: {
                items: 1,
                autoWidth: false,
            },
            640: {
                items: 1,

            }
        }
    });
    $(".owl-mtrus").owlCarousel({
        lazyLoad: true,
        loop: true,
        nav: true,
        responsive: {
            0: {
                autoWidth:true,
                margin: 8
            },

            1200: {
                margin: 24,
                items: 4
            }
        }
    });
});

// пока что не видел, чтобы это где-то использовалось
// window.Popper = require('popper.js').default;
require('bootstrap');
// require("jquery-form-styler/dist/jquery.formstyler");
require("object-fit-images");

require("@fancyapps/fancybox");
require("instafeed.js/instafeed");

// Валидация инпутов со всех страниц сайта тут (кроме полей завязаных на API Я.Карт)
require('./validation');

// Пока не отсортированные функции
require('./main');

require('./product-management');
require('bootstrap-datepicker/dist/js/bootstrap-datepicker');
require('bootstrap-datepicker/js/locales/bootstrap-datepicker.ru');

$(window).on('load', function () {
    $('.datepicker').datepicker({
        language: 'ru'
    });
});
$(document).ready(function () {
    $(this).on('ajaxComplete', function () {
        $('.datepicker').datepicker({
            language: 'ru'
        });
    });
});
