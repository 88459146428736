import {
    addSpaceToNumber,
    changePackType,
    changeCountInCart,
    calculateTotal,
    refreshPriceForProduct,
    getCartData,
    getCartItemsCount,
    setCartData
} from "./product-functions";

$(document).ready(function () {

    $('.basket-num').html(getCartItemsCount());

    // Всплывающая подсказка с корзиной
    $('.basket.ic').hover(function () {
        if (getCartItemsCount() !== 0 && $('#productAddToCart').is(':hidden')) {
            $.request('onGetCartItems', {
                complete: function () {
                    $('#basket-tooltip').css({'display': 'block'});
                    addSpaceToNumber('.total-price');
                    addSpaceToNumber('.subtotal');
                }
            });
        }
    }, function () {
        $('#basket-tooltip').css({'display': 'none'});
    });

    $('#noticed-about-cookie').on('click', function () {
        $.request('onNoticedAboutCookie', {});
        $(this).closest('.tooltip-wrap').hide();
    });

    ShowAndHideBasketButtons()
    addSpaceToNumber('.price');
    addSpaceToNumber('.price-cost');
    addSpaceToNumber('.upshot-right b');
    addSpaceToNumber('.result');
    addSpaceToNumber('#productPrice');
    addSpaceToNumber('#deliveryPrice');
    addSpaceToNumber('#sumPrice');

    let offerId = $(".actual-offer").val();
    let cartContent = $('#cart-content');

    $(document).on('ajaxComplete', function () {
        $('.basket-num').html(getCartItemsCount());
        calculateTotal();
    });

    // Обработка изменения варианта упаковки в козрине
    cartContent.on('change', '.cart-offer-select', function () {
        let card = $(this).parents('.basketCard');
        changePackType(card.data('id'), $('option:selected', this).val());
    });

    // Обработка изменения кол-ва единиц товара в корзине
    cartContent.on('click', '.quantity', function (event) {
        let quantityElem = $('input', this);
        let offerId = quantityElem.data('offer');
        if (event.target.classList.contains('plus')) {
            changeCountInCart(quantityElem, offerId);
        } else if (event.target.classList.contains('minus')) {
            changeCountInCart(quantityElem, offerId, false);
        }
    });

    // Обработка изменения варианта упаковки в каталоге
    $(".catalog-offer-select").on('change', function () {
        $(this).closest('.select-wrap').siblings(".card-price").hide();
        $(this).closest('.select-wrap').siblings(".offer" + $('option:selected', this).val()).show();
    });

    // Обработка изменения варианта упаковки в карточке товара и на странице преордера
    $('.offer-select').on('change', function () {
        offerId = $(".offer-select").val();
        let selectWrap = $(this).closest('.select-wrap');
        let price = parseInt(selectWrap.siblings("input.offer" + offerId).data('price'));
        selectWrap.siblings("input.actual-price").val(price);
        selectWrap.siblings("input.actual-offer").val(offerId);
        refreshPriceForProduct();
        $('#add-to-cart').text('в корзину');
        
        ShowAndHideBasketButtons()
    });

    function ShowAndHideBasketButtons() {
        let packInStockSelector = $('.offer-select option:selected').data('offerinstock');
        let offerInStock = (packInStockSelector == undefined ? Number(window.firstInStock) : Number(packInStockSelector));
        if (offerInStock == 0) {
            // Если offerInStock равен 0, скрываем кнопку "в корзину" и показываем кнопку "предзаказ"
            $('#add-to-cart').hide();
            $('#preorder').show();
        } else {
            // В противном случае, показываем кнопку "в корзину" и скрываем кнопку "предзаказ"
            $('#add-to-cart').show();
            $('#preorder').hide();
        }
    }

    // Обработка изменения кол-ва единиц товара в карточке товара
    $('.quantity').on('click', function (event) {
        let quantity = $('input', this);
        let quantityText = quantity.val().split(' ');
        let newCount = parseInt(quantityText[0]);
        if (event.target.classList.contains('plus')) {
            newCount += 1;
        } else if (event.target.classList.contains('minus')) {
            newCount -= 1;
            if (newCount < 1) {
                newCount = 1;
            }
        } else {
            return;
        }
        refreshPriceForProduct(newCount);
        quantity.val(newCount + ' ' + quantityText[quantityText.length - 1]);
    });

    // Обработка добавления в корзину
    $('#add-to-cart').on('click', function () {
        let count = parseInt($('.quantity .minus').siblings('input').val().split(' ')[0]);
        let cartData = getCartData() || {};

        if (cartData.hasOwnProperty(offerId)) {
            cartData[offerId]['count'] = parseInt(cartData[offerId]['count']) + count;
        } else {
            cartData[offerId] = {};
            cartData[offerId]['count'] = count;
        }
        cartData[offerId]['price'] = $('input.actual-price').val();
        setCartData(cartData);
        $('.basket-num').html(getCartItemsCount());

        $('#productAddToCart').show();
        setTimeout(function () {
            $('#productAddToCart').hide();
        }, 3000);
    });

    $('.basketH-close').on('click', function () {
        $(this).closest('div').hide();
    });
});
