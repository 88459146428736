export {
    addSpaceToNumber,
    changePackType,
    changeCountInCart,
    calculateTotal,
    refreshPriceForProduct,
    setCartData,
    getCartData,
    getCartItemsCount
};

function addSpaceToNumber(selector)
{
    let swapped = false;
    $(selector).map(function (index, value) {
        let currency = $(value).html().split(' ')[0];
        let price = $(value).html().split(' ')[1];
        if (isNaN(parseInt(price)) || parseInt(price) === 0) {
            price = [currency, currency = price][0];
            swapped = true;
        }
        price = price.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
        if (swapped) {
            $(value).html(price + ' ' + currency);
        } else {
            $(value).html(currency + ' ' + price);
        }
    });
}

function changePackType(oldOfferId, newOfferId)
{
    let cart = getCartData();
    let changedAmount = parseInt(cart[oldOfferId]['count']);
    delete cart[oldOfferId];
    setCartData(cart);
    if (cart[newOfferId]) {
        cart[newOfferId]['count'] += changedAmount;
        setCartData(cart);
        refreshCartContent();
    } else {
        $.request('onGetPrice', {
            data: {
                offer_id: newOfferId,
                count: changedAmount,
            },
            complete: function () {
                refreshCartContent();
            }
        });
    }
}

function refreshCartContent()
{
    $.request('onRefreshCart', {
        complete: function () {
            $(".cart-offer-select").select2({
                minimumResultsForSearch: -1
            });
            addSpaceToNumber('.upshot-right b');
            addSpaceToNumber('.result');
        }
    });
}

function changeCountInCart(inputElem, offerId, inc = true)
{
    let cart = getCartData();
    let count = parseInt(cart[offerId]['count']);
    if (inc) {
        count += 1;
    } else {
        if (count > 1) {
            count -= 1;
        }
    }
    cart[offerId]['count'] = count;
    let dataContainer = $('#offer' + offerId + ' .basketCard-col .close');
    let newPrData = dataContainer.data('request-data').split('[count]')[0] + '[count]:' + count;
    dataContainer.attr('data-request-data', newPrData);
    setCartData(cart);
    calculateTotal();
    let inputText = inputElem.val().split(' ');
    let units = inputText[inputText.length - 1];
    inputElem.val(count + ' ' + units);
    $('.basket-num').html(getCartItemsCount());
}

function calculateTotal()
{
    let total = 0;
    let cart = getCartData();
    $.each(cart, function (id, value) {
        let currentProductTotal = parseInt(value['count']) * parseInt(value['price']);
        total += currentProductTotal;
        $('.result' + id).html('руб. ' + currentProductTotal);
    });
    $('.upshot-right b').html('руб. ' + total);
    addSpaceToNumber('.upshot-right b');
    addSpaceToNumber('.result');
}

function refreshPriceForProduct(count = 0)
{
    if (count === 0) {
        count = parseInt($('.quantity input').val().split(' ')[0]);
    }
    let price = parseInt($('input.actual-price').val());
    $(".price").html('руб. ' + count * price);
    addSpaceToNumber('.price');
}

// Функции работы с куками корзины
function setCartData(data)
{
    document.cookie = "cart_items=" + JSON.stringify(data) + "; path=/";
}

function getCartData()
{
    return getCookie('cart_items');
}

function getCartItemsCount()
{
    let data = getCartData();
    let counter = 0;
    $.each(data, function (offerId, offer) {
        counter += parseInt(offer['count']);
    });
    return counter;
}

function getCookie(cookie_name)
{
    let value = "; " + document.cookie;
    let parts = value.split("; " + cookie_name + "=");
    if (parts.length === 2) {
        let dataToParse = parts.pop().split(";").shift().split('.').join(',');
        return JSON.parse(dataToParse);
    }
    return 0;
}
