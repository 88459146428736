$(document).ready(function () {

    // if ($('#instafeed').length) {
    //     function waitForjQuery() {
    //         if (typeof Instafeed != 'undefined') {
    //             let userFeed = new Instafeed({
    //                 get: 'user',
    //                 userId: '20425874966',
    //                 accessToken: "{{ settings.client_id }}",
    //                 resolution: 'low_resolution',
    //                 limit: 6,
    //             });
    //             userFeed.run();
    //         } else {
    //             window.setTimeout(function () {
    //                 waitForjQuery();
    //             }, 1000);
    //         }
    //     }
    //
    //     waitForjQuery();
    // }

    $('.video-play').on('click', function () {
        $('.beforePlayVideo').hide();
        $('.youtube-video').show();
    });

    //-----------------------------------
    function indentHh()
    {
        let hh = $('header').outerHeight();
        $('.geometr').css({'min-height': 'calc(100vh - ' + hh + 'px)'});
    }
    indentHh();
    $(window).resize(function () {
        indentHh()
    });
    //-----------------------------------
    $(".anchorLink, .geometr .arrow").click(function () {
        $("html, body").animate({
            scrollTop: $($(this).attr("href")).offset().top + "px"
        }, {
            duration: 1000,
            easing: "swing"
        });
        return false;
    });
    //-----------------------------------
    $(".hamburger").click(function () {
        $(this).toggleClass("is-active");
        $('body').toggleClass('open');
    });


    $('[data-fancybox="gallery"]').fancybox({
        infobar: false,
        toolbar: false,
        smallBtn: false
    });

    function title()
    {
        let elem = document.getElementsByClassName('article-text');
        let text = elem.innerHTML;
        let size = 75;
        let n = 220;
        for (let i = 0; i < elem.length; i++) { /* необходимо вставить цикл, чтоб получить все блоки с классом tit */
            if (elem[i].innerHTML.length > size) {
                text = elem[i].innerHTML.substr(0, n);
            }
            else {
                text = elem[i].innerHTML;
            }
            elem[i].innerHTML = text + '...';
        }
    }
    title();



    //-----------------------------------

    $(window).scroll(function (e) {
        let scrolled = $(window).scrollTop();

        $('.designBox-text .line-parallax.top').css('top', (200 - (scrolled * .06)) + 'px');
        $('.designBox-text .line-parallax.bottom').css('bottom', (2 - (scrolled * -.06)) + 'px');
        $('.designBox-pic').css('bottom', (-100 - (scrolled * -.09)) + 'px');

        if (screen.width <= '767') {
            $('.runningLine.h1').css('left', (2700 - (scrolled * +1.5)) + 'px');
        } else {
            $('.runningLine.h1').css('left', (1200 - (scrolled * +.4)) + 'px');
        }
    });

    $(".basket-btn").click(function () {
        $('.main-full-basketH').toggleClass("active");
        $('body').addClass('modal-open');
    });
    $(".bg-close").click(function () {
        $('.main-full-basketH').removeClass("active");
        $('body').removeClass('modal-open');
    });


    $('.btn-basket').click(function () {
        $(this).html('В корзине');
    });


    $('.wrap-phone-select').click(function () {
        $(this).parent().addClass('fill');
    });
    $('.main-focus').hover(function () {
        $(this).find('.main-input').focus();
    });

    $(".owl-mainCard").owlCarousel({
        margin: 32,
        loop: true,
        autoWidth: true,
        items: 1,
        dots: true,
        responsive: {
            0: {
                margin: 0,
            },
            575: {
                margin: 24,
            },
            768: {
                margin: 32,
            },
            992: {
                margin: 48
            }
        }
    });

    let target = document.querySelector('header.wow.fadeInDown.main');

    // configure the intersection observer instance
    let intersectionObserverOptions = {
        root: null,
        rootMargin: '48px',
        threshold: 1.0
    };

    let observer = new IntersectionObserver(onIntersection, intersectionObserverOptions);

    // provide the observer with a target
    observer.observe(target);

    function onIntersection(entries)
    {
        entries.forEach(entry => {
            if (entry.intersectionRatio < 1) {
                document.querySelector('.empty-basketH').classList.add('edit-pos')
            } else {
                document.querySelector('.empty-basketH').classList.remove('edit-pos')
            }
            target.classList.toggle('visible', entry.intersectionRatio > 0);

            // Are we in viewport?
            if (entry.intersectionRatio > 0) {
                // Stop watching
                // observer.unobserve(entry.target);
            }
        });
    }

    // $('.click-js').click(function () {
    //     const modal = $('.temporarily-modal');
    //     modal.addClass('active');
    //     setTimeout(function () {
    //         if (modal.hasClass('active')) {
    //             modal.removeClass('active');
    //         }
    //     }, 5000);
    // });
    // $('.close-modal').click(function () {
    //     $('.temporarily-modal').removeClass('active');
    // });

    // $('.video-play').click(function () {
    //
    //     $(".youtube-video iframe")[0].src += "?autoplay=1";
    //
    //
    //
    // });

    //-----------------------------------
});
